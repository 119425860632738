import React from "react";
import Data from "../../Data/Data.json";
import { HeroBackground } from "../Images/Images";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
              Company
            </h4>
            {/* <a className="btn btn-link" href="">About Us</a>
                        <a className="btn btn-link" href="">Contact Us</a>
                        <a className="btn btn-link" href="">Reservation</a>
                        <a className="btn btn-link" href="">Privacy Policy</a>
                        <a className="btn btn-link" href="">Terms & Condition</a> */}
            {Data.FooterLinks.map((key,index) => {
              return (
                <a className="btn btn-link" key={index} href={key.id}>
                  {key.Name}
                </a>
              );
            })}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
              Contact
            </h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>
              {Data.Address}
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              {Data.Phone}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>{Data.Email}
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                href={Data.Facebook}>
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
              Opening
            </h4>
            {Data.Timings.map((key, index) => {
              return (
                <span key={index}>
                  <h5 className="text-light fw-normal">{key.Days}</h5>
                  <p>{key.Time}</p>
                </span>
              );
            })}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
              The RedLion
            </h4>
            
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "400px" }}>
            <img src={HeroBackground} width={"150px"} alt="logo"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="https://www.theredlionpubsouthampton.co.uk">
                TheRedLion
              </a>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Managed By <a className="border-bottom" href="https://www.dazzlingwebplanet.com">
              Dazzling Web Planet
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Data from "../../Data/Data.json";
// import { MenuImg } from "../Images/Images";

const Menu = () => {
  const path = useLocation();
  const Navigate = useNavigate();
  const handleMenu = () => {
    Navigate("/Menu");
  };

  const downloadPDF = (menu) => {
    fetch(menu).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = menu;
        alink.click();
      });
    });
  };

  return (
    <div className=" py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            Food Menu
          </h5>
          {/* {!path.pathname.includes("Menu") ?<h1 className="mb-5">2 for £12.00</h1>:""} */}
        </div>
        <div
          className="tab-class text-center wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
            {/* <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active" data-bs-toggle="pill" href="#tab-1">
                        <i className="fa fa-coffee fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Popular</small>
                            <h6 className="mt-n1 mb-0">Breakfast</h6>
                        </div>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 pb-3" data-bs-toggle="pill" href="#tab-2">
                        <i className="fa fa-hamburger fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Special</small>
                            <h6 className="mt-n1 mb-0">Launch</h6>
                        </div>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 me-0 pb-3" data-bs-toggle="pill" href="#tab-3">
                        <i className="fa fa-utensils fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Lovely</small>
                            <h6 className="mt-n1 mb-0">Dinner</h6>
                        </div>
                    </a>
                </li> */}
          </ul>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              {!path.pathname.includes("Menu") ? (
                <button
                  onClick={handleMenu}
                  className="btn btn-lg mt-4  btn-primary"
                >
                  Full Menu <i className="fa fa-external-link-alt"></i>
                </button>
              ) : (
                <div className="container d-flex justify-content-center menu-list">
                  <ul>
                    <li>
                      <button
                        onClick={() => downloadPDF("menu.pdf")}
                        className="btn btn-sm mt-4 mx-3  btn-primary"
                      >
                        <i class="fas fa-download"></i> Full Menu
                      </button>
                    </li>

                    <li>
                      <button
                        onClick={() => downloadPDF("sundayroast.pdf")}
                        className="btn btn-sm mt-4 mx-3  btn-primary"
                      >
                        <i class="fas fa-download"></i> Sunday Roast Menu
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => downloadPDF("christmas.pdf")}
                        className="btn btn-sm mt-4 mx-3  btn-primary"
                      >
                        <i class="fas fa-download"></i> Christmas Menu
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;

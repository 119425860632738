import Testimonial1 from "../../img/testimonial-1.jpg";
import Testimonial2 from "../../img/testimonial-2.jpg";
import Testimonial3 from "../../img/testimonial-3.jpg";
import Testimonial4 from "../../img/testimonial-4.jpg";
import HeroPng from "../../img/redlion.png";
// import About1 from "../../img/lionabout-1.jpeg";
import About2 from "../../img/lionabout-2.jpeg";
import About3 from "../../img/lionabout-3.jpeg";
import About4 from "../../img/lionabout-4.jpeg";
import Team1 from "../../img/team-1.jpg";
import Team2 from "../../img/team-2.jpg";
import Team3 from "../../img/team-3.jpg";
import Team4 from "../../img/team-4.jpg";
import Menu from "../../img/lionmenu.png";


export const MenuImg        = Menu;
export const Abouts         = [About2, About3, About4, About2];
export const Testimonials   = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];
export const Teams          = [Team1, Team2, Team3, Team4, Team4];
export const HeroBackground = HeroPng;
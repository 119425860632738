import React from "react";
import { Testimonials } from "../Images/Images";
import { Carousel } from "react-responsive-carousel";
import Data from "../../Data/Data.json";

const Testimonial = () => {
  return (
    <div className=" py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            Testimonial
          </h5>
          <h1 className="mb-5">Our Clients Say!!!</h1>
        </div>

        <div>
          <Carousel
            className="owl-carousel testimonial-carousel"
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}>
            {Data.Testimonials.map((key,index) => {
              return (
                <div className="testimonial-item bg-transparent border rounded p-4" key={index}>
                  <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                  <p>
                    {key.Testimonial}
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid flex-shrink-0 rounded-circle"
                      src={Testimonials[index]}
                      alt="Testimonial User"
                    />
                    <div className="ps-3">
                      <h5 className="mb-1">{key.Name}</h5>
                      <small>{key.Profession}</small>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

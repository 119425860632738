import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SEO() {
  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>The RedLion - Southampton</title>
        <meta name="description" content="The RedLion Southampton is a traditional English pub, serving delicious food and drinks in a cozy atmosphere. Visit us for a great time with friends and family." />
        <meta name="keywords" content="The RedLion Southampton, English pub, food, drinks, cozy atmosphere, friends, family" />
        <meta property="og:title" content="The RedLion Southampton Pub" />
        <meta property="og:description" content="The RedLion Southampton is a traditional English pub, serving delicious food and drinks in a cozy atmosphere. Visit us for a great time with friends and family." />
        <meta property="og:image" content="https://www.redlionsouthampton.co.uk/images/redlion.png" />
        <meta property="og:url" content="https://www.redlionpubsouthampton.co.uk/about" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* rest of your component code here */}
    </div>
    </HelmetProvider>
  );
}

export default SEO;

import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import { Abouts } from "../Images/Images";

const About = (props) => {
    const path = useLocation();
    
    return(<div className="pt-3">
    <div className="container">
        <div className="row g-5 align-items-center">
            <div className="col-lg-6">
                <div className="row g-3">
                    <div className="col-6 text-start">
                        <img className="img-fluid rounded w-100 wow zoomIn" alt="About" data-wow-delay="0.1s" src={Abouts[0]}/>
                    </div>
                    <div className="col-6 text-start">
                        <img className="img-fluid rounded w-75 wow zoomIn"  alt="About" data-wow-delay="0.3s" src={Abouts[1]} style={{marginTop: "25%"}}/>
                    </div>
                    <div className="col-6 text-end">
                        <img className="img-fluid rounded w-75 wow zoomIn"  alt="About" data-wow-delay="0.5s" src={Abouts[2]}/>
                    </div>
                    <div className="col-6 text-end">
                        <img className="img-fluid rounded w-100 wow zoomIn" alt="About" data-wow-delay="0.7s" src={Abouts[3]}/>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>
                <h1 className="mb-4">Welcome to <i className={`${Data.Icon} text-primary me-2`}></i>{Data.Name}</h1>
                {!path.pathname.includes("About") ? <>
                <p className="mb-4">{Data.About[0]}</p>
                <p className="mb-4">{Data.About[1]}</p></>
                :
                Data.About.map((key,index)=>{
                    return <p className="mb-4" key={index}>{key}</p>
                })} 
                {/* <div className="row g-4 mb-4">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                            <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">{Data.Years}</h1>
                            <div className="ps-4">
                                <p className="mb-0">Century</p>
                                <h6 className="text-uppercase mb-0">Pub</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                            <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">{Data.NoChef}</h1>
                            <div className="ps-4">
                                <p className="mb-0">Popular</p>
                                <h6 className="text-uppercase mb-0">Master Chefs</h6>
                            </div>
                        </div>
                    </div>
                </div> */}
                {!path.pathname.includes("About") &&<a className="btn btn-primary py-3 px-5 mt-2" href="/About">Read More</a>}
            </div>
        </div>
    </div>
</div>);
};

export default About;

import React from "react";
import Data from "../../Data/Data.json";

const Contact = (props) => {
  return (
    <>
      <div className="py-5 px-0 animate fadeInUp" data-wow-delay="0.1s">
        <div className="row g-0">
          <div className="col-md-6">
            <div className="video">
              {/* <button type="button" className="btn-play"  onClick={()=>setModal(true)}>
                    <span></span>
                </button>*/}
            </div>
          </div>
          <div className="col-md-6 bg-dark d-flex align-items-center">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              <h5 className="section-title ff-secondary text-start text-primary fw-normal">
                Contact Us
              </h5>
              <h1 className="text-white mb-4">For Bookings</h1>
              <div className="row g-3 text-white">

                <p className="mb-2">
                  <i className="fa fa-map-marker-alt me-3"></i>
                  {Data.Address}
                </p>
                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3"></i>
                  {Data.Phone}
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3"></i>{Data.Email}
                </p>
                <div className="d-flex pt-2">
                  <a
                    className="btn btn-outline-light btn-social"
                    href={Data.Facebook}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </div>
              </div>
              {/* <form>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="name" placeholder="Your Name"/>
                                <label htmlFor="name">Your Name</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input type="email" className="form-control" id="email" placeholder="Your Email"/>
                                <label htmlFor="email">Your Email</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating date" id="date3" data-target-input="nearest">
                                <input type="datetime-local" className="form-control datetimepicker-input" id="datetime" placeholder="Date & Time" data-target="#date3" data-toggle="datetimepicker" />
                                <label htmlFor="datetime">Date & Time</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <select className="form-select" id="select1">
                                  {options.map((key)=>{
                                    return <option value={key} key={key}>{key}</option>
                                  })}
                                </select>
                                <label htmlFor="select1">No Of People</label>
                              </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Special Request" id="message" style={{height: "100px"}}></textarea>
                                <label htmlFor="message">Special Request</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                        </div>
                    </div>
                </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;


// import Service from "../Services/Service";
import About from "../About/About";
import Menu from "../Menu/Menu";
import Contact from "../Contact/Contact";
import Team from "../Team/team";
import Testimonial from "../Testimonial/Testimonial";
import Data from "../../Data/Data.json";
const Home = () => {
    const Active = Data.Active
  return (
    <>
      {/* {!!Active.Service      &&  <Service />     } */}
      {!!Active.About        &&  <About />       }
      {!!Active.Menu         &&  <Menu />        }
      {!!Active.Contact      &&  <Contact />     }
      {!!Active.Team         &&  <Team />        }
      {!!Active.Testimonial  && <Testimonial />  }
    </>
  );
};

export default Home;

import "./css/bootstrap-override.css";
import "./css/animate/animate.min.css";
import "./App.css";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Banner from "./Components/Banner/Banner";
import { useEffect } from "react";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Team from "./Components/Team/team";
import Menu from "./Components/Menu/Menu";
import Privacy from "./Components/PolicynConditions/Privacy";
import Conditions from "./Components/PolicynConditions/Conditions";
import Cookie from "./Components/Cookies";
import SEO from "./Components/SEO/Seo";

function App() {
  useEffect(() => {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
      if ($(this).scrollTop() > 45) {
        $(".navbar").addClass("sticky-top shadow-sm");
      } else {
        $(".navbar").removeClass("sticky-top shadow-sm");
      }
    });
  }, []);

  const handleOnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className=" bg-white p-0">
      <SEO/>
      <Router>
        <div className="position-relative p-0">
          <Navbar />
          <Banner />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" exact element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Conditions />} />
        </Routes>
        <Cookie />
      </Router>

      <Footer />

      <button
        onClick={handleOnTop}
        className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="fa fa-arrow-up"></i>
      </button>
    </div>
  );
}

export default App;

import React from "react";
import Data from "../../Data/Data.json";

const Conditions = () => {
  return (
    <>
      <div className="py-5 px-0 animate fadeInUp" data-wow-delay="0.1s">
        <div className="row g-0">
          <div className=" bg-dark d-flex align-items-center">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              <h5 className="section-title ff-secondary text-start text-primary fw-normal">
                Terms & Conditions
              </h5>
              <div className="row g-3 text-white">
                {Data.Privacy}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Conditions;